import React, { useState } from "react";
import styled from "styled-components";

const AnswerButton = (props) => {
  const [colourState, setColourState] = useState("#ffffff");
  const [hoverState, setHoverState] = useState("unset");

  const Button = styled.span`
    border: 1px solid ${colourState};
    background-color: ${colourState};
    font-size: 25px;
    display: block;
    color: #000000;
    padding: 10px 20px;
    margin-bottom: 20px;
    width: 480px;
    box-sizing: border-box;

    &:hover {
      background-color: ${hoverState};
      color: #ffffff;
      border-color: ${colourState};
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      width: 90%;
      margin-bottom: 5px;
    }
  `;

  const handleAnswer = (answer) => {
    if (answer) {
      setColourState("#27ae60");
      setHoverState("#27ae60");
      setTimeout(() => {
        setColourState("#ffffff");
        setHoverState("unset");
        props.changeScore(1);
        props.reset();
      }, 800);
    } else {
      setColourState("#c0392b");
      setHoverState("#c0392b");
      setTimeout(() => {
        setColourState("#ffffff");
        setHoverState("unset");
        props.changeScore(-1);
        props.reset();
      }, 800);
    }
  };

  return (
    <Button onClick={() => handleAnswer(props.answer)}>{props.children}</Button>
  );
};

export default AnswerButton;
