import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";

import quizData from "../data/quizData";
import cities from "../data/cities";

import Image from "./image";
import AnswerButton from "./answerButton";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 100vh;
`;

const Game = (props) => {
  const [reset, toggleReset] = useState(0);
  const quizItem = _.sample(quizData);

  const handleReset = () => {
    props.setCount(props.count + 1);

    if (props.count >= 9) {
      return props.screenChanger("ScoreScreen");
    }

    return toggleReset(!reset);
  };

  const changeScore = (value) => {
    if (Math.sign(props.score + value) === -1) {
      return;
    }
    props.setScore(props.score + value);
  };

  const generateAnswers = (cities, answer) => {
    let answers = [];
    answers.push(answer);

    let i = 0;

    while (i < 3) {
      let currentAnswer = _.sample(cities);
      if (answer.text === currentAnswer || _.includes(answers, currentAnswer)) {
        continue;
      } else {
        answers.push(currentAnswer);
        i = i + 1;
      }
    }

    answers = _.shuffle(answers);

    return answers;
  };

  const answers = generateAnswers(cities, quizItem.answer);

  return (
    <Layout>
      <Image src={quizItem.image} />
      {answers.map((item) => (
        <AnswerButton
          answer={item.correct}
          reset={handleReset}
          changeScore={changeScore}
        >
          {item.text ? item.text : item}
        </AnswerButton>
      ))}
    </Layout>
  );
};

export default Game;
