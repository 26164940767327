import API_KEY from "../config";

const quizData = [
  {
    id: 1,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-Wellington,NZ&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Wellington, New Zealand",
      correct: true,
    },
  },
  {
    id: 2,
    image: `https://maps.googleapis.com/maps/api/streetview?location="Salta,AR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Salta, Argentina",
      correct: true,
    },
  },
  {
    id: 3,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Buenos%20Aires,AR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Buenos Aires, Argentina",
      correct: true,
    },
  },
  {
    id: 4,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Sydney,AU&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Sydney, Australia",
      correct: true,
    },
  },
  {
    id: 5,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Melbourne,AU&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Melbourne, Australia",
      correct: true,
    },
  },
  {
    id: 6,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Vienna,AT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Vienna, Austria",
      correct: true,
    },
  },
  {
    id: 7,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Linz,AT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Linz, Austria",
      correct: true,
    },
  },
  {
    id: 8,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Dhaka,BD&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Dhaka, Bangladesh",
      correct: true,
    },
  },
  {
    id: 9,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Salvador,BR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Salvador, Brazil",
      correct: true,
    },
  },
  {
    id: 10,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Phnom%20Penh,KH&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Phnom Penh, Cambodia",
      correct: true,
    },
  },
  {
    id: 11,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Edmonton,CA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Edmonton, Canada",
      correct: true,
    },
  },
  {
    id: 12,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Bogotá,CO&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Bogotá, Colombia",
      correct: true,
    },
  },
  {
    id: 13,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Copenhagen,DK&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Copenhagen, Denmark",
      correct: true,
    },
  },
  {
    id: 14,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Helsinki,FI&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Helsinki,Finland",
      correct: true,
    },
  },
  {
    id: 15,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Paris,FR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Paris, France",
      correct: true,
    },
  },
  {
    id: 16,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Athens,GR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Athens, Greece",
      correct: true,
    },
  },
  {
    id: 17,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Sparta,GR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Sparta, Greece",
      correct: true,
    },
  },
  {
    id: 18,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Jakarta,ID&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Jakarta, Indonesia",
      correct: true,
    },
  },
  {
    id: 19,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Medan,ID&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Medan, Indonesia",
      correct: true,
    },
  },
  {
    id: 20,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Dublin,IE&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Dublin, Ireland",
      correct: true,
    },
  },
  {
    id: 21,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Cork,IE&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Cork, Ireland",
      correct: true,
    },
  },
  {
    id: 22,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Jerusalem,IL&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Jerusalem, Israel",
      correct: true,
    },
  },
  {
    id: 23,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Rome,IT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Rome,Italy",
      correct: true,
    },
  },
  {
    id: 24,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Milan,IT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Milan, Italy",
      correct: true,
    },
  },
  {
    id: 25,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Naples,IT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Naples, Italy",
      correct: true,
    },
  },
  {
    id: 26,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Tokyo,JP&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Tokyo, Japan",
      correct: true,
    },
  },
  {
    id: 27,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Amman,JO&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Amman, Jordan",
      correct: true,
    },
  },
  {
    id: 28,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Seoul,KR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Seoul, Korea",
      correct: true,
    },
  },
  {
    id: 29,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Mexico%20City,MX&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Mexico City, Mexico",
      correct: true,
    },
  },
  {
    id: 30,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Auckland,NZ&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Auckland, New Zealand",
      correct: true,
    },
  },
  {
    id: 31,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Hamilton,NZ&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Hamilton, New Zealand",
      correct: true,
    },
  },
  {
    id: 32,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Christchurch,NZ&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Christchurch, New Zealand",
      correct: true,
    },
  },
  {
    id: 33,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Oslo,NO&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Oslo, Norway",
      correct: true,
    },
  },
  {
    id: 34,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Quezon%20City,PH&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Quezon City, Phillipines",
      correct: true,
    },
  },
  {
    id: 35,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Lisbon,PT&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Lisbon, Portgual",
      correct: true,
    },
  },
  {
    id: 36,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Moscow,RU&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Moscow, Russia",
      correct: true,
    },
  },
  {
    id: 37,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Saint%20Petersburg,RU&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Saint Petersburg, Russia",
      correct: true,
    },
  },
  {
    id: 38,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Johannesburg,ZA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Johannesburg, South Africa",
      correct: true,
    },
  },
  {
    id: 39,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Madrid,ES&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Madrid, Spain",
      correct: true,
    },
  },
  {
    id: 40,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Barcelona,ES&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Barcelona, Mexico",
      correct: true,
    },
  },
  {
    id: 41,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Stockholm,SE&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Stockholm, Sweden",
      correct: true,
    },
  },
  {
    id: 42,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Zürich,CH&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Zürich, Switzerland",
      correct: true,
    },
  },
  {
    id: 43,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Instanbul,TR&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Instanbul, Turkey",
      correct: true,
    },
  },
  {
    id: 44,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Kyiv,UA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Kyiv, Ukraine",
      correct: true,
    },
  },
  {
    id: 45,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Liverpool,UK&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Liverpool, United Kingdom",
      correct: true,
    },
  },
  {
    id: 46,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Manchester,UK&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Manchester, United Kingdom",
      correct: true,
    },
  },
  {
    id: 47,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Glasgow,UK&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Glasgow, United Kingdom",
      correct: true,
    },
  },
  {
    id: 48,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Trenton,USA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Trenton, USA",
      correct: true,
    },
  },
  {
    id: 49,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Naples,USA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Naples, USA",
      correct: true,
    },
  },
  {
    id: 50,
    image: `https://maps.googleapis.com/maps/api/streetview?location=64.2679699,-15.1956004&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Höfn, Iceland",
      correct: true,
    },
  },
  {
    id: 51,
    image: `https://maps.googleapis.com/maps/api/streetview?location=65.2627651,-14.4065253&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Egilsstaðir, Iceland",
      correct: true,
    },
  },
  {
    id: 52,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-45.0391153,168.6329893&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Queenstown, New Zealand",
      correct: true,
    },
  },
  {
    id: 53,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Tirana,AL&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Tirana, Albania",
      correct: true,
    },
  },
  {
    id: 54,
    image: `https://maps.googleapis.com/maps/api/streetview?location=40.3301159,19.4731503&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Orikum, Albania",
      correct: true,
    },
  },
  {
    id: 55,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-34.8597363,-56.2001925&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Montevideo, Uruguay",
      correct: true,
    },
  },
  {
    id: 56,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-30.8898593,-55.5464821&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Rivera, Uruguay",
      correct: true,
    },
  },
  {
    id: 57,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-33.8637957,151.211813&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Sydney, Australia",
      correct: true,
    },
  },
  {
    id: 49,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Naples,USA&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Naples, USA",
      correct: true,
    },
  },
  {
    id: 50,
    image: `https://maps.googleapis.com/maps/api/streetview?location=64.2679699,-15.1956004&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Höfn, Iceland",
      correct: true,
    },
  },
  {
    id: 51,
    image: `https://maps.googleapis.com/maps/api/streetview?location=65.2627651,-14.4065253&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Egilsstaðir, Iceland",
      correct: true,
    },
  },
  {
    id: 52,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-45.0391153,168.6329893&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Queenstown, New Zealand",
      correct: true,
    },
  },
  {
    id: 53,
    image: `https://maps.googleapis.com/maps/api/streetview?location=Tirana,AL&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Tirana, Albania",
      correct: true,
    },
  },
  {
    id: 54,
    image: `https://maps.googleapis.com/maps/api/streetview?location=40.3301159,19.4731503&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Orikum, Albania",
      correct: true,
    },
  },
  {
    id: 55,
    image: `https://maps.googleapis.com/maps/api/streetview?location=-34.8597363,-56.2001925&source=outdoor&size=480x360&key=${API_KEY}`,
    answer: {
      text: "Montevideo, Uruguay",
      correct: true,
    },
  },
];

export default quizData;
