import React from "react";
import styled from "styled-components";

import CustomButton from "./customButton";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  margin-top: 0px;
  margin-bottom: 80px;
  width: 100%;
`;

const StartScreen = (props) => {
  return (
    <Layout>
      <Title>Geo Guesser</Title>
      <CustomButton onClick={props.screenChanger} nextScreen={"Game"}>
        Start
      </CustomButton>
      <CustomButton>What's New</CustomButton>
      <CustomButton>About</CustomButton>
    </Layout>
  );
};

export default StartScreen;
