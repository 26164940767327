import React from 'react';
import styled from "styled-components";

const Img = styled.img`
	margin-bottom: 50px;

	@media screen and (max-width: 480px) {
		width:90%;
		margin-bottom: 30px;
	}
`;

const Image = (props) => {
	return (
		<Img src={props.src} alt="quiz-location"/>
	)
}

export default Image;