import React, { useState } from "react";

import "./App.css";

import StartScreen from "./components/startScreen";
import Game from "./components/game";
import ScoreScreen from "./components/scoreScreen";

const App = () => {
  const [currentScreen, setCurrentScreen] = useState("StartScreen");
  let [score, setScore] = useState(0);
  let [count, setCount] = useState(0);

  const renderScreen = (screen) => {
    switch (screen) {
      case "StartScreen":
        return <StartScreen screenChanger={screenChanger} />;
      case "Game":
        return (
          <Game
            screenChanger={screenChanger}
            score={score}
            setScore={setScore}
            count={count}
            setCount={setCount}
          />
        );
      case "ScoreScreen":
        return (
          <ScoreScreen
            screenChanger={screenChanger}
            score={score}
            setScore={setScore}
            count={count}
            setCount={setCount}
          />
        );
      default:
        <StartScreen screenChanger={screenChanger} />;
    }
  };

  const screenChanger = (screen) => {
    return setCurrentScreen(screen);
  };

  return <div className="App">{renderScreen(currentScreen)}</div>;
};

export default App;
