const cities = [
  "Kabul, Afghanistan",
  "Farah, Afghanistan",
  "Ballsh, Albania",
  "Maliq, Albania",
  "Shijak, Albania",
  "Algiers, Algeria",
  "Barika, Algeria",
  "Buenos Aires, Argentina",
  "Salta, Argentina",
  "San Luis, Argentina",
  "Sydney, Australia",
  "Melbourne, Australia",
  "Perth, Australia",
  "Vienna, Austria",
  "Linz, Austria",
  "Babek, Azerbaijan",
  "Nassau, Bahamas",
  "Dhaka, Bangladesh",
  "Brussels, Belgium",
  "Rio de Janeiro, Brazil",
  "Salvador, Brazil",
  "Sofia, Bulgaria",
  "Phnom Penh, Cambodia",
  "Douala, Cameroon",
  "Toronto, Canada",
  "Edmonton, Canada",
  "Vancouver, Canada",
  "Beijing, China",
  "Shanghai, China",
  "Los Lagos, Chile",
  "Bogotá, Columbia",
  "Havana, Cuba",
  "San José, Costa Rica",
  "Copenhagen, Denmark",
  "Giza, Egypt",
  "Helsinki, Finland",
  "Paris, France",
  "Faa'a, French Polynesia",
  "Libreville, Gabon",
  "Abuko, Gambia",
  "Gali, Georgia",
  "Batumi, Georgia",
  "Khoni, Georgia",
  "Berlin, Germany",
  "Athens, Greece",
  "Sparta, Greece",
  "Budapest, Hungary",
  "Mumbai, India",
  "Delhi, India",
  "Bangalore, India",
  "Jakarta, Indonesia",
  "Medan, Indonesia",
  "Karaj, Iran",
  "Baghdad, Iraq",
  "Dublin, Ireland",
  "Cork, Ireland",
  "Jerusalem, Israel",
  "Rome, Italy",
  "Milan, Italy",
  "Naples, Italy",
  "Kingston, Jamaica",
  "Tokyo, Japan",
  "Osaka, Japan",
  "Amman, Jordan",
  "Almaty, Kazakhstan",
  "Seoul, South Korea",
  "Mexico City, Mexico",
  "Ulaanbaatar, Mongolia",
  "Casablanca, Morocco",
  "Auckland, New Zealand",
  "Wellington, New Zealand",
  "Hamilton, New Zealand",
  "Christchurch, New Zealand",
  "Oslo, Norway",
  "Karachi, Pakistan",
  "Panamá, Panama",
  "Lima, Peru",
  "Quezon City, Phillipines",
  "Warsaw, Poland",
  "Lisbon, Portugal",
  "Bucharest, Romania",
  "Moscow, Russia",
  "Saint Petersburg, Russia",
  "Apia, Samoa",
  "Dammam, Saudi Arabia",
  "Jeddah, Saudi Arabia",
  "Freetown, Sierra Leone",
  "Johannesburg, South Africa",
  "Cape Town, South Africa",
  "Madrid, Spain",
  "Barcelona, Spain",
  "Colombo, Sri Lanka",
  "Omdurman, Sudan",
  "Stockholm, Sweden",
  "Zürich, Switzerland",
  "Damascus, Syria",
  "Dar es Salaam, Tanzania",
  "Istanbul, Turkey",
  "Kyiv, Ukraine",
  "Birmingham, United Kingdom",
  "London, United Kingdom",
  "Trenton, USA",
  "Naples, USA",
  "Reykjavík, Iceland",
  "Vík í Mýrdal, Iceland",
  "Montevideo, Uruguay",
  "Salto, Uruguay",
  "Rivera, Uruguay",
];

export default cities;
