import React from "react";
import styled from "styled-components";

const ScoreScreen = (props) => {
  const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    height: 100vh;
  `;

  const ScoreCard = styled.div`
    background-color: #ffffff;
    color: #000000;
    width: 66%;
    padding: 20px 0px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

    @media screen and (max-width: 750px) {
      width: 80%;
    }

    @media screen and (max-width: 480px) {
      width: 90%;
    }
  `;

  const Score = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: #000000;
  `;

  const Rank = styled.h3`
    margin: 0px;
    display: inline-block;
  `;

  const Text = styled.p`
    font-size: 20px;
  `;

  const Button = styled.span`
    border: 1px solid #34495e;
    background-color: #34495e;
    font-size: 25px;
    display: block;
    color: #ffffff;
    padding: 10px 20px;
    margin-bottom: 20px;
    width: 20%;
    margin: 30px auto 0px auto;

    &:hover {
      background-color: #ffffff;
      color: #000000;
      border-color: #000000;
      cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
      width: 25%;
    }

    @media screen and (max-width: 1000px) {
      width: 33%;
    }

    @media screen and (max-width: 750px) {
      width: 50%;
    }
  `;

  const handleClick = () => {
    props.setScore(0);
    props.setCount(0);
    props.screenChanger("Game");
  };

  const scoreOutput = () => {
    if (props.score === 0) {
      return <Rank>Geographically Challenged</Rank>;
    } else if (props.score > 0 && props.score <= 3) {
      return <Rank>Student</Rank>;
    } else if (props.score > 3 && props.score <= 7) {
      return <Rank>Drifter</Rank>;
    } else if (props.score > 7 && props.score <= 9) {
      return <Rank>Professor</Rank>;
    } else if (props.score === 10) {
      return <Rank>Atlas</Rank>;
    }
  };

  return (
    <Layout>
      <ScoreCard>
        <h2>Score</h2>
        <Text>You rank is: {scoreOutput(props.score)}</Text>
        <Text>
          Your score is: <Score>{props.score}</Score>
        </Text>
        <Button onClick={() => handleClick()}>Play Again</Button>
      </ScoreCard>
    </Layout>
  );
};

export default ScoreScreen;
