import React from "react";
import styled from "styled-components";

const Button = styled.span`
  border:1px solid #ffffff;
  background-color: unset;
  font-size: 25px;
  display: block;
  color: #ffffff;
  padding:10px 20px;
  margin-bottom: 20px;
  width: 20%;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    border-color: #000000;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    width: 25%;
  }

  @media screen and (max-width: 1000px) {
    width: 33%;
  }

  @media screen and (max-width: 750px) {
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;

const CustomButton = (props) => {
  return (
    <Button onClick={() => props.onClick(props.nextScreen)}>{props.children}</Button>
  )
}

export default CustomButton;